body {
  margin: 0;
  font-family: "Impact", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Impact";
  src: url("./fonts/impact.ttf") format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

$padding: 1rem;
.word-input-fetcher-container {
  margin-top: $padding * 5;
}

.button-pink {
  background-color: #000000 !important;
}

button {
  color: white !important;
}

.white-text {
  color: white !important;
}

.button-outlined-white {
  border: 3px solid white !important;
}
